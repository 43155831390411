/**
 * @author Artiom Tretjakovas <artiom.tretjakovas2@gmail.com>
 */
import React from 'react';
import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    FormControlLabelProps,
    FormControlProps,
    styled,
} from '@mui/material';

import { FieldBase, FieldBaseProps } from './Fields';

const StyledLabel = styled(FormControlLabel)({
    alignItems: 'flex-start',
    '&.MuiFormControlLabel-label': {
        paddingTop: 3,
    },
});

const charValues = ['Y', 'N'];
const boolValues = [true, false];

/**
 * @extends FieldBaseProps
 * @property {?string} label - sets description for BooleanField
 */
export interface BooleanFieldProps extends FieldBaseProps<string | boolean | null> {
    label?: FormControlLabelProps['label'];
    booleanValues?: boolean;
    checkBoxProps?: CheckboxProps;
}

/**
 * Boolean field used for boolean input ('Y'/'N')
 * @component
 * @wraps FieldBase
 */
export const BooleanField = ({ label, booleanValues = false, checkBoxProps, ...otherProps }: BooleanFieldProps) => (
    <FieldBase {...otherProps}>
        {({ editable, value, setFieldValue, textFieldProps }) => (
            <FormControl error={true} {...(textFieldProps as FormControlProps)}>
                <StyledLabel
                    style={{ alignItems: 'center' }}
                    disabled={!editable}
                    control={
                        <Checkbox
                            size="small"
                            color="primary"
                            {...checkBoxProps}
                            checked={value === (booleanValues ? boolValues : charValues)[0]}
                            onChange={e =>
                                setFieldValue((booleanValues ? boolValues : charValues)[e.target.checked ? 0 : 1])
                            }
                        />
                    }
                    label={label ?? ''}
                />
            </FormControl>
        )}
    </FieldBase>
);
